nav img {
    background-image: url('../../../public/Logo-01.png');
    background-size: cover; /* Adjust to fit the image appropriately */
    background-position: center; /* Center the background image */
    color: transparent; /* Hide the text */
    -webkit-background-clip: text; /* Clip the background to the text */
    background-clip: text;
    font-size: 2em;
    margin: 0;
    height: 40px;
    display: inline-block;
}