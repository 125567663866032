.policy-section {
    padding: 10px;
    margin-top:100px;
    display: flex;
   flex-direction: column;
    justify-content: center;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
}

.policy-section h1{
    font-size: 2em;
    font-weight: bold;
}

.policy-section h3{
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

.policy-section p{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}